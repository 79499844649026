/* eslint-disable no-nested-ternary */
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { EmailOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  Container,
  CssBaseline,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import getUserObject from '../api/getUserObject';
import SelectWorkspace from '../components/magento_auth/SelectWorkspace';
import * as types from '../constants/ActionTypes';
import dataHubTextDev from '../data-hub-text-dev.svg';
import dataHubTextProd from '../data-hub-text-prod.svg';
import { ReactComponent as GoogleIcon } from '../google/btn_google_light_normal_ios.svg';
// import useBackendData from '../hooks/useBackendData';
import useDataProviderData from '../hooks/useDataProviderData';

function MagentoAuthorization() {
  const { user, route } = useAuthenticator((context) => [
    context.user,
    context.route,
  ]);
  // const { data: workspaces = [] } = useBackendData(user && `/workspaces`, {});
  const { data: configData } = useDataProviderData(
    user && `/data-providers/config/`,
    {}
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (route === 'authenticated' && configData) {
      dispatch({
        type: types.SET_DATA_PROVIDER_SETTINGS,
        settings: configData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData, route]);

  useEffect(() => {
    async function fetchGraphQL() {
      const {
        userObject: userObject2,
        currentAuthenticatedUser: currentAuthUser,
      } = await getUserObject();

      if (userObject2) {
        batch(() => {
          dispatch({
            type: types.SET_CURRENT_AUTHENTICATED_USER,
            currentAuthenticatedUser: currentAuthUser,
          });
          dispatch({
            type: types.SET_USER_OBJECT,
            userObject: userObject2,
          });
        });
      }
    }

    if (route === 'authenticated') {
      fetchGraphQL();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  return (
    <>
      <Container
        component="main"
        maxWidth={route !== 'authenticated' ? 'xs' : 'lg'}
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {route !== 'authenticated' && (
            <>
              <Box sx={{ width: '100%' }}>
                <Box
                  sx={{
                    mb: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    alt="Data Hub Logo"
                    src={
                      process.env.REACT_APP_USER_BRANCH === 'prod'
                        ? dataHubTextProd
                        : dataHubTextDev
                    }
                    style={{ height: '75px' }}
                  />
                  <Typography
                    align="center"
                    color="text.secondary"
                    variant="subtitle1"
                  >
                    A really good and simple tool for connecting all your data
                    to your favorite Dashboarding platform.
                  </Typography>
                </Box>

                <Box
                  sx={{
                    my: 2,
                    mt: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Button
                    fullWidth
                    onClick={() => {
                      Auth.federatedSignIn({
                        provider: 'Google',
                        customState: JSON.stringify({
                          location,
                        }),
                      });
                    }}
                    startIcon={<GoogleIcon />}
                    sx={{ background: '#fff', textTransform: 'none' }}
                    variant="outlined"
                  >
                    Continue with Google
                  </Button>
                </Box>
                <Box
                  sx={{
                    my: 2,
                  }}
                >
                  <Divider>
                    <Typography color="text.secondary" variant="caption">
                      or
                    </Typography>
                  </Divider>
                </Box>

                <Box
                  sx={{
                    my: 2,

                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Button
                    fullWidth
                    onClick={() => {
                      setChecked((prev) => !prev);
                    }}
                    startIcon={<EmailOutlined />}
                    sx={{ background: '#fff', textTransform: 'none' }}
                    variant="outlined"
                  >
                    Use my email
                  </Button>
                </Box>
              </Box>

              <Collapse in={checked}>
                <Box sx={{ mt: 2 }}>
                  <Authenticator
                    loginMechanisms={['email']}
                    signUpAttributes={['name', 'email']}
                    socialProviders={[]}
                  />
                </Box>
              </Collapse>

              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <Typography
                  align="center"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                  variant="caption"
                >
                  By signing up for Data Hub, you agree to the{' '}
                  <Link
                    color="secondary"
                    href="https://datahub.gladior.com/terms-of-service"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Terms of Service
                  </Link>
                  . View our{' '}
                  <Link
                    color="secondary"
                    href="https://datahub.gladior.com/privacy-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                  .
                </Typography>

                <Typography
                  align="center"
                  color="text.secondary"
                  variant="caption"
                >
                  {'Copyright © '}
                  Data Hub powered by Gladior {new Date().getFullYear()}.
                </Typography>
              </Box>
            </>
          )}
          {route === 'authenticated' && <SelectWorkspace />}
        </Box>
      </Container>
    </>
  );
}

export default MagentoAuthorization;
