import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@mui/styles';
import { Storage } from 'aws-amplify';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

const visibility = 'protected';

// Storage.configure({ level: visibility });

const useStyles = makeStyles((theme) => ({
  userAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  userAvatarImage: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.action.disabled}`,
  },

  bigAvatar: {
    margin: 10,
    width: 120,
    height: 120,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '10px',
  },
}));

function UserAvatar({ name, avatar, size }) {
  const classes = useStyles();
  const [avatarImageSrc, setAvatarImageSrc] = useState('');

  const buttonClassname = clsx({
    [classes.bigAvatar]: size === 'big',
    [classes.small]: size === 'small',
    [classes.userAvatarImage]: avatarImageSrc !== '',
    [classes.userAvatar]: !avatarImageSrc,
  });

  useEffect(() => {
    let active = true;
    async function getS3AvatarLink() {
      const result = await Storage.get(avatar.file.key, {
        level: visibility,
        identityId: avatar.identityId,
      });
      if (active) {
        setAvatarImageSrc(result);
      }
    }

    if (avatar) {
      getS3AvatarLink();
    } else if (active) {
      setAvatarImageSrc('');
    }

    return () => {
      active = false;
    };
  }, [avatar]);

  return (
    <>
      {avatarImageSrc ? (
        <Avatar className={buttonClassname} src={avatarImageSrc} />
      ) : (
        <Avatar className={buttonClassname}>{name.charAt(0)}</Avatar>
      )}
    </>
  );
}

export default UserAvatar;
